@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/src/css/main";

html {
	background: $color-gray url('../img/bg/bg.png') no-repeat center top;
	background-size: cover;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-black;
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-black;
	}
}

header {
	padding: rem(20) 0;

	h1 {
		margin: 0 0 rem(20) 0;

		> img {
			margin: 0 auto;
		}
	}

	.vol {
		border-bottom: 4px solid $color-black;
		border-top: 8px solid $color-black;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		letter-spacing: letter-spacing(200);
		padding: rem(15) 0;
		text-indent: text-indent(200);

		> div {
			flex: 0 0 auto;

			span {
				color: $color-blue;
			}
		}
	}

	.socials {
		background: $color-black;
		color: $color-white;
		font-size: rem(24);
		left: 0;
		padding: rem(15);
		position: fixed;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1030;

		> ul {
			list-style: none;
			margin: 0;
			padding: 0;

			> li {
				margin-bottom: rem(20);

				> a {
					color: $color-white;

					@include hover-focus {
						color: $color-blue;
					}
				}
			}
		}

		> span {
			display: inline-block;
			font-family: 'Oswald', sans-serif;
			font-weight: 700;
			text-orientation: sideways;
			transform: rotate(-180deg);
			writing-mode: vertical-lr;
		}
	}
}

main {
	.inner {
		align-items: center;
		border-bottom: 4px solid $color-black;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding: rem(25) 0 rem(40) 0;
		text-align: center;

		@include desktop-sm-down {
			display: block;
		}

		> div {
			flex: 0 0 50%;

			&:first-child {
				border-right: 1px solid $color-black;
				padding-right: rem(40);

				@include desktop-sm-down {
					border-bottom: 1px solid $color-black;
					border-right: 0;
					margin-bottom: rem(40);
					padding-bottom: rem(40);
					padding-right: 0;
				}

				> h2 {
					font-family: 'Oswald', sans-serif;
					font-size: rem(42);
					font-weight: 400;
					letter-spacing: letter-spacing(20);
					margin: 0 0 rem(25) 0;
					text-indent: text-indent(20);

					> span {
						font-weight: 700;
					}
				}

				> img {
					margin: 0 auto;
				}

				> p {
					font-size: rem(14);
					line-height: line-height(24, 14);
					margin: rem(20) 0 0 0;
					text-transform: none;
				}
			}

			&:last-child {
				padding-left: rem(40);

				@include desktop-sm-down {
					padding-left: 0;
				}

				> h3 {
					font-family: 'Oswald', sans-serif;
					font-size: rem(29);
					font-weight: 700;
					letter-spacing: letter-spacing(225);
					margin: 0;

					> img {
						margin: 0 auto;
					}

					> span {
						display: block;
						margin: rem(20) 0;
					}
				}

				> .shoe {
					border-bottom: 8px solid $color-black;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					padding-bottom: rem(80);

					@include desktop-sm-down {
						display: block;
					}

					> div {
						flex: 0 0 50%;

						&:first-child {
							padding-right: rem(20);

							@include desktop-sm-down {
								margin-bottom: rem(20);
								padding-right: 0;
							}

							img {
								margin: 0 auto;
							}
						}

						&:last-child {
							p {
								font-size: rem(18);
								font-weight: 900;
								line-height: line-height(21, 18);
								margin-bottom: rem(11);

								&.disclaimer {
									font-size: rem(10);
									line-height: line-height(12, 10);
								}
							}

							.btn {
								background: $color-white;
								border-radius: rem(30);
								color: $color-black;
								display: inline-block;
								margin-bottom: rem(20);
								padding: rem(10) rem(20);
								text-align: center;
								vertical-align: middle;

								> img, span {
									display: inline-block;
									vertical-align: middle;
								}

								img {
									max-width: rem(100);
								}
							}

							.share {
								font-family: 'Oswald', sans-serif;
								letter-spacing: letter-spacing(225);
								list-style: none;
								margin: 0 0 rem(20) 0;
								padding: 0;
								text-indent: text-indent(225);

								> li {
									display: inline-block;
									vertical-align: middle;

									> a {
										color: $color-blue;

										@include hover-focus {
											color: $color-black;
										}
									}
								}
							}

							.rules-link {
								font-family: 'Oswald', sans-serif;
								letter-spacing: letter-spacing(225);
								text-decoration: underline;
								text-indent: text-indent(225);

								@include hover-focus {
									text-decoration: none;
								}
							}


						}
					}
				}
			}
		}
	}
}

footer {
	padding: rem(40) 0;

	.container {

		.inner {
			align-items: center;
			border-bottom: 4px solid $color-black;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			padding-bottom: rem(40);

			@include desktop-sm-down {
				display: block;
			}

			> div {
				flex: 0 0 50%;

				&:first-child {
					> h3 {
						font-family: 'Oswald', sans-serif;
						font-size: rem(66);
						font-weight: 400;
						margin: 0;

						> span {
							font-weight: 700;
						}
					}

					> .featured {
						font-family: 'Oswald', sans-serif;
						font-size: rem(18);
						letter-spacing: letter-spacing(300);
						margin: rem(15) 0;
						text-indent: text-indent(300);
					}
				}

				&:last-child {
					padding-left: rem(30);

					@include desktop-sm-down {
						margin-top: rem(30);
						padding-left: 0;
					}

					.newsletter {
						border: 1px solid $color-black;
						margin-bottom: rem(30);
						min-height: rem(330);
						padding: rem(30);

						.newsletter-title {
							font-family: 'Oswald', sans-serif;
							font-size: rem(29);
							letter-spacing: letter-spacing(400);
							margin-bottom: rem(30);
							text-indent: text-indent(400);

							> span {
								font-weight: 700;
							}
						}

						form {
							input {
								background: none;
								border: 0;
								border-bottom: 4px solid $color-black;
								color: $color-black;
								display: block;
								font-size: 1rem;
								letter-spacing: letter-spacing(300);
								line-height: 1;
								margin-bottom: rem(20);
								padding: 0 0 rem(10) 0;
								text-align: center;
								text-indent: text-indent(300);
								width: 100%;

								@include placeholder {
									color: $color-black;
									text-transform: uppercase;
								}
							}

							button {
								background: $color-black;
								border: 0;
								border-radius: rem(30);
								color: $color-white;
								font-family: 'Oswald', sans-serif;
								letter-spacing: letter-spacing(400);
								line-height: 1;
								padding: rem(20) rem(30);
								text-align: center;
								text-indent: text-indent(400);
								text-transform: uppercase;

								@include hover-focus {
									background: $color-white;
									color: $color-black;
								}
							}
						}
					}

					.copyright {
						align-items: center;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;

						@include phone-down {
							display: block;
						}

						> a {
							display: inline-block;
							flex: 0 0 auto;
							opacity: 1;
							vertical-align: middle;

							@include hover-focus {
								opacity: 0.8;
							}
						}

						> div {
							flex: 1 1 auto;
							font-size: rem(10);
							letter-spacing: letter-spacing(100);
							line-height: line-height(18, 10);
							margin-left: rem(20);
							text-align: left;
							text-indent: text-indent(100);

							@include phone-down {
								margin-left: 0;
								margin-top: rem(20);
								text-align: center;
							}

							p {
								margin: 0;

								a {
									@include hover-focus {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
		}

	}
}

.popup {
	background: $color-gray url('../img/bg/bg.png') no-repeat center top;
	background-size: cover;
	border: 4px solid $color-black;
	margin: 20px auto;
	max-width: 500px;
	padding: rem(30);
	position: relative;
	text-align: center;
	width: auto;
}